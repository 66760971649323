/**
 * Descriptografa uma string com base na lógica usada na criptografia.
 *
 * @param {string} input - A string criptografada.
 * @returns {string} A string original descriptografada.
 */
export function decrypt(input) {
  input = atob(input);
  let result = "";
  let xorValue = 0; // Valor inicial do XOR

  for (let i = 0; i < input.length; i++) {
    const encryptedCharCode = input.charCodeAt(i);

    // Remove o incremento de 1
    const xorResult = encryptedCharCode - 1;

    // Recupera o caractere original usando o XOR com o valor anterior
    const originalCharCode = xorResult ^ xorValue;

    // Atualiza o XOR para o próximo caractere
    xorValue = xorResult;

    // Adiciona o caractere original ao resultado
    result += String.fromCharCode(originalCharCode);
  }

  return result.trim();
}
