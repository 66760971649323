import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { DatagridSX, ListSX } from "../../../styled/custom";
import CustomFilterCustomerMedia from "../../../filter/custom-filter-customer-media";
import ButtonDownloadField from "../../../ui/button-download-field";
import rowCustomerMediaStyle from "../../../ui/row-customer-media-style";
import MediaClassField from "../../../ui/media-class-field";
import { CustomerMediaPreview } from "../../../ui/customer-media-preview";

const CustomerMediaList = (props) => {
  return (
    <List
      title="Cesta de Produtos"
      filters={CustomFilterCustomerMedia}
      actions={null}
      sx={ListSX}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={false}
        rowStyle={rowCustomerMediaStyle()}
        sx={DatagridSX}
      >
        <TextField source="id" sortable={false} />
        <DateField
          source="created_at"
          locales="pt-BR"
          label="Criado em"
          sortable={false}
          showTime
        />
        <MediaClassField source="media_class" label="Classe" sortable={false} />
        <TextField
          source="category_media_name"
          label="Categoria"
          sortable={false}
        />
        <TextField source="name" label="Nome / Título" sortable={false} />
        <TextField source="type" label="Tipo" sortable={false} />
        <TextField source="description" label="Descrição" sortable={false} />
        <ButtonDownloadField source="media" label="Download" />
        <CustomerMediaPreview label="Pré-visualização" />
      </Datagrid>
    </List>
  );
};

export default CustomerMediaList;
