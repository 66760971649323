import React from "react";
import { Create, SimpleForm, useGetList, FormDataConsumer } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSelect from "../../../ui/custom-select";
import { ContentPageSX } from "../../../styled/custom";
import { hasValue, filesSize } from "../../../utils/schemas";
import CustomTextField from "../../../ui/custom-text-field";
import FileUploadInput from "../../../ui/custom-file-update-input";

export const MEDIA_MAXIMUM_FILES_SIZE = 600_000_000;
export const MEDIA_CLASSES = [
  {
    id: "Audio",
    name: "Audio",
  },
  {
    id: "Texto",
    name: "Texto",
  },
];

// Esquema atualizado com validações condicionais
const Schema = Yup.object({
  media_class: hasValue("Selecione uma classe"),
  category_media_id: hasValue("Selecione uma categoria"),
  files: Yup.mixed().when("media_class", {
    is: "Audio",
    then: filesSize(
      MEDIA_MAXIMUM_FILES_SIZE,
      "Selecione 1 ou mais arquivos com tamanho máximo de 600 MB no total"
    ),
    otherwise: Yup.mixed().notRequired(), // Não é necessário para 'Texto'
  }),
  name: Yup.string().when("media_class", {
    is: "Texto",
    then: Yup.string().required("O título é obrigatório para classe Texto"),
    otherwise: Yup.string().notRequired(),
  }),
  body: Yup.string()
    .trim()
    .when("media_class", {
      is: "Texto",
      then: Yup.string()
        .trim()
        .nullable()
        .required("O corpo é obrigatório para classe Texto"),
      otherwise: Yup.string().notRequired(),
    }),
});

const MediaCreate = () => {
  const { data, isLoading } = useGetList("category-media", {
    pagination: { page: 1, perPage: 1000000 },
    filter: {
      activated: true,
    },
  });

  return (
    <Create title="Novo Produto da Cesta" redirect="list" sx={ContentPageSX}>
      <SimpleForm noValidate resolver={yupResolver(Schema)}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const isHypermedia = formData.media_class !== "Texto";
            return (
              <>
                <CustomSelect
                  data={MEDIA_CLASSES}
                  name="media_class"
                  label="Classe"
                  multiple={false}
                  fullWidth
                  isRequired
                />
                {!isLoading && (
                  <CustomSelect
                    data={data}
                    name="category_media_id"
                    label="Categoria"
                    multiple={false}
                    fullWidth
                    isRequired
                  />
                )}
                {!isHypermedia && (
                  <>
                    <CustomTextField name="name" label="Título" fullWidth />
                    <CustomTextField
                      name="body"
                      label="Corpo"
                      fullWidth
                      multiline
                      rows={6}
                    />
                  </>
                )}
                {isHypermedia && (
                  <FileUploadInput
                    source="files"
                    sourceDescription="descriptions"
                    label="Tamanho Máximo dos Arquivos Somados: 600 MB"
                    multiple={true}
                    maxSize={MEDIA_MAXIMUM_FILES_SIZE}
                    accept="application/*, video/*, audio/*, image/*"
                  />
                  // <FileInput
                  //   source="files"
                  //   label="Tamanho Máximo dos Arquivos Somados: 500 MB"
                  //   multiple={true}
                  //   maxSize={MEDIA_MAXIMUM_FILES_SIZE}
                  //   accept="application/*, video/*, audio/*, image/*"
                  // >
                  //   <FileField src="src" title="title" source="src" />
                  // </FileInput>
                )}
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default MediaCreate;
