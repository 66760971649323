import React, { useState } from "react";
import { useNotify, useRecordContext } from "react-admin";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import Preview from "@mui/icons-material/Preview";

// Para evitar que o Datagrid utilize o evento...
export const MouseEventEater = ({ children }) => {
  const eat = (e) => {
    console.log("eat", e);
    e.preventDefault();
    e.stopPropagation();
  };
  return <div onClick={eat}>{children}</div>;
};

export const TextMediaShow = ({ open, onClose, title, body, preview }) => {
  const notify = useNotify();

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText("" + title + "\n\n" + body);
      notify("Copiado!", { type: "success" });
    } catch (err) {
      notify("Erro ao copiar", { type: "error" });
    }
  };

  if (open) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle color="blue">{title}</DialogTitle>
        <DialogContent>
          <TextField multiline fullWidth value={body} />
        </DialogContent>
        <DialogActions>
          {preview && (
            <Typography color="error">
              Esta é uma leitura limitada do conteúdo total, por favor realizar
              o download.
            </Typography>
          )}
          <Button onClick={copyToClipboard} color="success">
            Copiar
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose(e);
            }}
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};

const ControlTextPreview = ({ data }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  if (!record || !record.body) {
    // Não renderiza o botão se não houver texto no campo "body"
    return null;
  }

  const handleOpen = (e) => {
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  if (record) {
    return (
      <MouseEventEater>
        {/* Botão para abrir o dialog */}
        <Tooltip title="Por favor realizar o download para a cópia completa do texto, esta é só uma pré-visualização com tamanho limitado">
          <Button variant="contained" color="info" onClick={handleOpen}>
            <Preview />
          </Button>
        </Tooltip>
        <TextMediaShow
          open={open}
          onClose={handleClose}
          body={record.body}
          title={record.name}
          preview
        />
      </MouseEventEater>
    );
  }
  return null;
};

export { ControlTextPreview };
