import React from "react";
import {
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  BooleanInput,
  useGetList,
  FormDataConsumer,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import CustomTextField from "../../../ui/custom-text-field";
import CustomSelect from "../../../ui/custom-select";
import { filesSize, hasValue, minLength } from "../../../utils/schemas";
import { ContentPageSX } from "../../../styled/custom";
import { MEDIA_MAXIMUM_FILES_SIZE } from "./create";

const Schema = Yup.object({
  category_media_id: hasValue("Selecione uma categoria"),
  name: Yup.string().when("media_class", {
    is: "Texto",
    then: Yup.string().required("O título é obrigatório para a classe Texto"),
    otherwise: minLength(3, "Digite o nome"),
  }),
  body: Yup.string()
    .trim()
    .when("media_class", {
      is: "Texto",
      then: Yup.string()
        .trim()
        .nullable()
        .required("O corpo é obrigatório para a classe Texto"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
});

const MediaEdit = (props) => {
  const { data, isLoading } = useGetList("category-media", {
    pagination: { page: 1, perPage: 1000000 },
  });

  return (
    <Edit
      title={<EditTitle subtitle="Editar Produto da Cesta: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const isHypermedia = formData.media_class !== "Texto";
            return (
              <>
                <CustomTextField source="id" label="Id" disabled />
                <CustomTextField source="media_class" label="Classe" disabled />
                {!isLoading && (
                  <CustomSelect
                    data={data}
                    name="category_media_id"
                    label="Categoria"
                    multiple={false}
                    fullWidth
                    isRequired
                  />
                )}
                <CustomTextField
                  name="name"
                  label={isHypermedia ? "Nome" : "Título"}
                  fullWidth
                />
                {!isHypermedia && (
                  <CustomTextField
                    name="body"
                    label="Corpo"
                    fullWidth
                    multiline
                    rows={6}
                  />
                )}
                {isHypermedia && (
                  <CustomTextField
                    name="description"
                    label="Descrição"
                    fullWidth
                  />
                )}
                {isHypermedia && (
                  <FileInput
                    source="file"
                    label="Tamanho Máximo do Arquivo: 600MB"
                    maxSize={MEDIA_MAXIMUM_FILES_SIZE}
                    accept="application/*,video/*,audio/*,image/*"
                  >
                    <FileField src="src" title="title" />
                  </FileInput>
                )}
                <BooleanInput source="active" label="Ativo" />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default MediaEdit;
