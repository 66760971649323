import React from "react";
import { useRecordContext } from "react-admin";
import Article from "@mui/icons-material/Article";
import AudioFile from "@mui/icons-material/AudioFile";

const styles = {
  main: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  },
};


const MediaClassField = ({ source, text }) => {
  const record = useRecordContext();
  if (record) {
    const recordSource = record[source];
    if (recordSource) {
      return (
        <span style={styles.main}>
          {recordSource === "Texto" ? <Article color="primary" /> : <AudioFile color="success" />}
          {recordSource}
        </span>
      );
    } else return null;
  } else return null;
};

MediaClassField.defaultProps = {
  addLabel: true,
  source: "class",
};

export default MediaClassField;
