import React from "react";
import { Show, TextField, SimpleShowLayout, DateField } from "react-admin";
import { ContentPageSX, SimpleShowLayoutSX } from "../../../styled/custom";
import CustomerMediaShowTitle from "../../../ui/customer-media-show-title";
import ButtonDownloadField from "../../../ui/button-download-field";
import { CustomerMediaPreview } from "../../../ui/customer-media-preview";

const CustomerMediaShow = (props) => (
  <Show title={<CustomerMediaShowTitle />} sx={ContentPageSX}>
    <SimpleShowLayout sx={SimpleShowLayoutSX}>
      <TextField source="id" />
      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
      <TextField
        source="category_media_name"
        label="Categoria"
        sortable={false}
      />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <TextField source="description" label="Descrição" sortable={false} />
      <ButtonDownloadField source="media" label="Download" />
      <CustomerMediaPreview label="Pré-visualização" />
    </SimpleShowLayout>
  </Show>
);

export default CustomerMediaShow;
