import React, { useState } from "react";
import { useRefresh } from "react-admin";
import { useRecordContext } from "react-admin";
import { saveAs } from "file-saver";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import getLocation from "../utils/location";
import { fetchURLFile } from "../provider/data-provider";
import SubmitButton from "../styled/submit-button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MouseEventEater, TextMediaShow } from "./control-text";

const downloadFile = async (e, source, record, callback) => {
  e.stopPropagation();

  getLocation(async (position, error) => {
    if (position) {
      const data = await fetchURLFile(record.id, source, {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      const { result } = data;

      if (record.media_class !== "Texto") {
        if (result) {
          if (Array.isArray(result))
            result.forEach((item, i) => {
              saveAs(item.url, item.url.substr(item.url.lastIndexOf("/") + 1));
            });
          else
            saveAs(
              result.url,
              result.url.substr(result.url.lastIndexOf("/") + 1)
            );
        } else alert("Não foi possível fazer download do arquivo.");

        callback(true);
      } else {
        callback(result.body);
      }
    }

    if (error && error.code) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Permissão não concedida");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Localização não é permitida");
          break;
        case error.TIMEOUT:
          alert("Timeout");
          break;
        default:
          alert("Algum erro inesperado");
          break;
      }
    }
  });
};

const ButtonDownloadField = ({ source }) => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState("");

  if (record)
    return (
      <>
        <MouseEventEater>
          <SubmitButton
            full={false}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setLoading(true);

              downloadFile(e, source, record, (value) => {
                if (record.media_class === "Texto") {
                  console.log("Abrindo");
                  setBody(value);
                  setOpen(true);
                } else {
                  refresh();
                }
                setLoading(false);
              });
            }}
          >
            <DownloadRoundedIcon />
          </SubmitButton>
        </MouseEventEater>
        {open && (
          <TextMediaShow
            open={open}
            onClick={e => { e.stopPropagation(); e.preventDefault(); }}
            onClose={() => setOpen(false)}
            body={body}
            title={record.name}
          />
        )}
        {loading && (
          <Backdrop
            sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  else return null;
};

export default ButtonDownloadField;
