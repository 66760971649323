import React from "react";
import TextField from "@mui/material/TextField";
import { useInput } from "react-admin";
import { useDropzone } from "react-dropzone";
import {
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";

// Este componente permite que junto ao arquivo tenha outro campo com as descrições destes arquivos
const FileUploadInput = ({
  source,
  sourceDescription,
  label,
  accept,
  multiple = false,
}) => {
  const {
    field,
    fieldState: { error },
  } = useInput({ source });

  const {
    field: { value: descriptions, onChange: onChangeDescription },
  } = useInput({ source: sourceDescription });

  const onDrop = (acceptedFiles) => {
    if (multiple) {
      field.onChange([...field.value, ...acceptedFiles]);
      onChangeDescription([...descriptions, ...acceptedFiles.map((i) => "")]);
    } else {
      field.onChange(acceptedFiles[0]);
      onChangeDescription("");
    }
  };

  const onRemove = (index) => {
    if (multiple) {
      let newFiles = [...field.value];
      let newDescriptions = [...descriptions];
      newFiles.splice(index, 1);
      newDescriptions.splice(index, 1);
      onChangeDescription(newDescriptions);
      field.onChange(newFiles);
    } else {
      field.onChange(null);
      onChangeDescription(null);
    }
  };

  const handleChangeDescription = (index, value) => {
    if (multiple) {
      let newDescriptions = [...descriptions];
      newDescriptions[index] = value;
      onChangeDescription(newDescriptions);
    } else {
      onChangeDescription(value);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed",
          background: "lightgray",
          borderColor: isDragActive ? "primary.main" : "text.disabled",
          borderRadius: "8px",
          padding: "16px",
          textAlign: "center",
          cursor: "pointer",
          width: "100%",
          display: "flex",
        }}
      >
        <input {...getInputProps()} />
        <Typography
          variant="body1"
          color={isDragActive ? "primary" : "textSecondary"}
        >
          {isDragActive
            ? "Solte os arquivos aqui..."
            : `Arraste e solte arquivos aqui ou clique para selecionar (${label})`}
        </Typography>
      </Box>
      {field.value &&
        (Array.isArray(field.value) ? (
          <Box mt={2}>
            <Typography variant="subtitle2">Arquivos selecionados:</Typography>
            <List>
              {field.value.map((file, index) => (
                <ListItem
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <ListItemIcon>
                    <IconButton
                      color="secondary"
                      onClick={() => onRemove(index)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <Typography color="red">{file.name}</Typography>
                      <TextField
                        label="Descrição"
                        value={descriptions[index]}
                        onChange={(e) =>
                          handleChangeDescription(index, e.target.value)
                        }
                      />
                    </div>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        ) : (
          <Box mt={2}>
            <Typography variant="subtitle2">Arquivo selecionado:</Typography>
            <Typography>{field.value.name}</Typography>
          </Box>
        ))}

      {error && (
        <Typography color="error" variant="body2" mt={1}>
          {error.message}
        </Typography>
      )}
    </>
  );
};

export default FileUploadInput;
