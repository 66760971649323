import React, { useEffect, useState, useRef } from "react";
import { useRecordContext } from "react-admin";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import { decrypt } from "../utils/crypto";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

const ControlAudio = ({ multiple, data }) => {
  const [audio, setAudio] = useState();
  const [index, setIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const record = useRecordContext();
  const mData = multiple ? (record.files ? record.files : data) : record;

  // Verifica se a classe da midia é audio.
  const isAudio = record ? record.media_class === "Audio" : false;

  const next = (e) => {
    setAutoPlay(true);

    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    var newIndex = index + 1;
    if (newIndex >= mData.length) newIndex = 0;

    setIndex(newIndex);
  };

  useEffect(() => {
    if (multiple && mData) {
      if (mData.length > 0 && index < mData.length) {
        setAudio(mData[index].url);
      }
    } else {
      setAudio(mData.url);
    }
  }, [index]);

  if (!isAudio) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 2,
      }}
    >
      <audio
        controls
        autoPlay={autoPlay}
        src={audio}
        preload="none"
        controlsList="nodownload noplaybackrate nofullscreen noremoteplayback"
      />
      {multiple ? (
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          onClick={next}
        >
          <SkipNextRoundedIcon />
          <br />
          <span style={{ fontSize: "0.6rem", display: "block" }}>
            {index + 1} / {mData.length}
          </span>
        </button>
      ) : null}
    </div>
  );
};

export default ControlAudio;

// Versão que permite apenas o afiliado escutar 30% do audio original e desobfusca a URL do audio.
const ControlAudioPreview = ({ data }) => {
  const [index, setIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState("");
  const record = useRecordContext();
  const audioRef = useRef(null); // Ref para o objeto Audio
  const mData = record;

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pausa o áudio atual ao trocar de índice
    }

    try {
      // Cria uma nova instância de áudio para o novo índice
      if (mData && index != null) {
        let url = mData.url;
        if (!url && !(typeof url === "string")) return;

        const audio = new Audio(decrypt(url));
        audioRef.current = audio;

        // Limita o tempo de audição a 30%
        const handleTimeUpdate = () => {
          if (!duration) {
            setDuration(audio.duration);
          }
          const maxTime = audio.duration * 0.3;
          if (audio.currentTime >= maxTime) {
            audio.pause();
            audio.currentTime = 0; // Reinicia o áudio
            setIsPlaying(false);
          }
        };

        audio.addEventListener("timeupdate", handleTimeUpdate);

        // Começa a tocar.
        audioRef.current.play();
        setIsPlaying(true);

        // Limpa eventos antigos ao desmontar o componente
        return () => {
          audio.removeEventListener("timeupdate", handleTimeUpdate);
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          audioRef.current = null;
        };
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  }, [index]);

  const togglePlay = React.useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (index === null) {
        setIndex(0);
      }

      const audio = audioRef.current;
      if (!audio) return;

      if (isPlaying) {
        audio.pause();
        audio.currentTime = 0;
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    },
    [index, isPlaying]
  );

  if (!mData) {
    return null;
  }
  if (mData.type && mData.type.toLowerCase() !== "mp3") {
    return null;
  }

  return (
    <Tooltip title="Por favor realizar o download da mídia, esta é só uma pré-visualização da mídia com tempo limitado">
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 8,
        }}
      >
        <Button
          onClick={togglePlay}
          style={{
            backgroundColor: isPlaying ? "#d32f2f" : "#388e3c", // Vermelho para pausa, verde para reprodução
            color: "white",
          }}
        >
          {isPlaying ? (
            <>
              <PauseCircleOutlineIcon />
            </>
          ) : (
            <>
              <PlayCircleOutlineIcon />
            </>
          )}
        </Button>
        <span>{duration ? `Tempo: ${duration.toFixed(1)}s` : ""}</span>
      </div>
    </Tooltip>
  );
};

export { ControlAudioPreview };
