import React from "react";
import { useRecordContext } from "react-admin";
import { ControlAudioPreview } from "./control-audio";
import { ControlTextPreview } from "./control-text";


const CustomerMediaPreview = ({ data }) => {
  const record = useRecordContext();
  if (record) {

    if (record.media_class === 'Audio') {
      return <ControlAudioPreview />
    } else {
      return <ControlTextPreview />
    }
  }

};

export { CustomerMediaPreview };
